import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { CoreComponent } from './application/core/core.component';
import { ErrorComponent } from './application/auth/components/error/error.component';
import { AccessComponent } from './application/auth/components/access/access.component';
import { NotfoundComponent } from './application/auth/components/notfound/notfound.component';
import { PrivacyPolicyComponent } from './application/privacy-policy/privacy-policy.component';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: '',
                    component: CoreComponent,
                    canActivate: [AuthGuard],
                    children: [
                        {
                            path: 'user',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import('./application/users/users.module').then(
                                    (m) => m.UsersModule
                                ),
                        },
                        {
                            path: 'script',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './application/script/script.module'
                                ).then((m) => m.ScriptModule),
                        },
                        {
                            path: 'system-setting',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './application/system-setting/system-setting.module'
                                ).then((m) => m.SystemSettingModule),
                        },
                        {
                            path: 'report',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './application/report/report.module'
                                ).then((m) => m.ReportModule),
                        },
                        {
                            path: 'iterstitial-ad-setting',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './application/interstitial-ad/interstitial-ad.module'
                                ).then((m) => m.InterstitialAdModule),
                        },
                        {
                            path: 'support-request',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './application/support-request/support-request.module'
                                ).then((m) => m.SupportRequestModule),
                        },
                        {
                            path: 'iterstitial-ad-report',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './application/interstitial-ad-report/interstitial-ad-report.module'
                                ).then((m) => m.InterstitialAdReportModule),
                        },
                        {
                            path: 'user-entry-log',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './application/user-entry-log/user-entry-log.module'
                                ).then((m) => m.UserEntryLogModule),
                        },
                        {
                            path: 'push-notification',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './application/push-notification/push-notification.module'
                                ).then((m) => m.PushNotificationModule),
                        },
                        {
                            path: 'app-version',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './application/app-version/app-version.module'
                                ).then((m) => m.AppVersionModule),
                        },
                        {
                            path: 'home-screen',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './application/home-screen/home-screen.module'
                                ).then((m) => m.HomeScreenModule),
                        },
                        {
                            path: 'search-history',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './application/search-history/search-history.module'
                                ).then((m) => m.SearchHistoryModule),
                        },
                        {
                            path: 'get-in-touch',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    '././application/get-in-touch/get-in-touch.module'
                                ).then((m) => m.GetInTouchModule),
                        },
                        {
                            path: 'app-ads',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    '././application/app-ads/app-ads.module'
                                ).then((m) => m.AppAdsModule),
                        },
                        {
                            path: 'system-setting-v2',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import('./application/system-setting-v2/system-setting-v2.module').then(m => m.SystemSettingV2Module)
                        }
                        /* {
                            path: 'country',
                            canActivate: [AuthGuard],
                            loadChildren: () =>
                                import(
                                    './application/country/country.module'
                                ).then((m) => m.CountryModule),
                        }, */
                      
                    ],
                },
                {
                    path: 'auth',
                    loadChildren: () =>
                        import('./application/auth/auth.module').then(
                            (m) => m.AuthModule
                        ),
                },
                {
                    path: 'privacy-policy',
                    component: PrivacyPolicyComponent,
                },
                {
                    path: 'notfound',
                    component: NotfoundComponent,
                },
                {
                    path: 'error',
                    component: ErrorComponent,
                },
                {
                    path: 'unauthorized',
                    component: AccessComponent,
                },
                {
                    path: '**',
                    redirectTo: '/notfound',
                },
            ],
            {
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
                onSameUrlNavigation: 'reload',
            }
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
