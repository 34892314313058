import { NgModule } from '@angular/core';
import { MenuModule } from 'primeng/menu';
import { ChartModule } from 'primeng/chart';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { MenubarModule } from 'primeng/menubar';
import { CalendarModule } from 'primeng/calendar';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { ColorPickerModule } from 'primeng/colorpicker';
import { KeyFilterModule } from 'primeng/keyfilter';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ImageModule } from 'primeng/image';
import { AvatarModule } from 'primeng/avatar';
import { DragDropModule } from 'primeng/dragdrop';
import { ProgressBarModule } from 'primeng/progressbar';
import {MultiSelectModule} from 'primeng/multiselect';

const PrimeNgModules = [
    MenuModule,
    ToastModule,
    TableModule,
    ChartModule,
    ButtonModule,
    RippleModule,
    MenubarModule,
    SidebarModule,
    PasswordModule,
    CalendarModule,
    InputTextModule,
    PanelMenuModule,
    ProgressSpinnerModule,
    ToggleButtonModule,
    DialogModule,
    FileUploadModule,
    InputSwitchModule,
    ConfirmDialogModule,
    TooltipModule,
    DropdownModule,
    ColorPickerModule,
    KeyFilterModule,
    RadioButtonModule,
    InputTextareaModule,
    ImageModule,
    AvatarModule,
    DragDropModule,
    ProgressBarModule,
    MultiSelectModule
];

@NgModule({
    imports: [PrimeNgModules],
    exports: [PrimeNgModules],
})
export class PrimeNgFormsModules {}
